<template>
  <div v-if="handlePage">
    <b-card no-body class="mb-0">
      <div class="m-2-br-bottm--2">
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            ><h4 class="card-title">{{ $t("rule.ConfigureEvents") }}</h4>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            <b-button
              variant="outline-primary"
              size="sm"
              @click="routeToEventList()"
              class="v2-back"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">{{ $t("back.back") }}</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div class="frm-paddig">
        <b-row>
          <b-col
            cols="12"
            md="12"
            class="d-flex align-items-center justify-content-start"
          >
          </b-col>
          <b-col
            cols="12"
            md="12"
            class="d-flex align-items-center justify-content-start"
          >
            <div class="setup-event-btn">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                @click="setUpUserConfigurations()"
              >
                {{ $t("rule.SetupNewEvent") }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <Loader :show="showLoading" />
  </div>
  <div v-else>
    <div class="misc-wrapper">
      <div class="misc-inner p-2 p-sm-3">
        <div class="w-100 text-center">
          <h2 class="mb-1">{{ $t("rule.EventNotAvailableTXT") }}</h2>
          <p class="mb-2">
            {{ $t("rule.EventNotAvailableMSG") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BCardBody, BCard, BButton, BCol, BRow } from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import RuleService from "@/libs/api/rule-service";
import Loader from "@/layouts/components/Loader.vue";
export default {
  components: {
    BCardBody,
    BCard,
    BButton,
    ToastificationContent,
    BCol,
    BRow,
    Loader
  },
  directives: {
    Ripple
  },
  data() {
    return {
      handlePage: true,
      showLoading: false,
      deviceId: null,
      unitId: null,
      rules: []
    };
  },
  mounted() {
    this.deviceId = this.$route.query.deviceId;
    this.unitId = this.$route.query.unitId;
    this.getListOfApplicableRules();
  },
  methods: {
    setUpUserConfigurations() {
      this.$router.push({
        name: "rules-config",
        query: { deviceId: this.deviceId, unitId: this.unitId }
      });
    },
    routeToEventList() {
      this.$router.push({
        name: "event-list",
        query: { deviceId: this.deviceId, unitId: this.unitId }
      });
    },
    async getListOfApplicableRules() {
      try {
        this.showLoading = true;
        let response = await new RuleService().getListOfApplicableRules({
          unit_id: this.unitId,
          id: this.deviceId
        });
        if (response && response.data) {
          this.rules = response.data.device_rules;
          if (this.rules && this.rules.length == 0) {
            this.handlePage = false;
          }
        } else if (response && response.error && response.error.message) {
          this.handlePage = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
        this.showLoading = false;
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.setup-event-btn {
  width: $percent_100;
  text-align: center;
}
</style>
